<template>
    <v-card flat class="rounded-lg" width="70%">
        <v-card class="d-inline-flex" flat>
            <v-btn
                class="align-self-center ml-2"
                icon
                large
                color="gray"
                :to="{ name: 'sign in' }"
            >
                <v-icon dark>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <v-card-title class="pa-4 text-h5">
                Password recovery
            </v-card-title>
        </v-card>
        <v-card-subtitle class="px-6 pt-0 pb-3 text-subtitle-1">
            Please enter your email to recover your password
        </v-card-subtitle>
        <v-card-text class="pa-6 pt-0">
            <v-form v-model="valid" ref="form" @submit.prevent="resetPassword">
                <v-text-field
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    label="Email"
                    required
                />
            </v-form>
            <v-row v-if="success">
                <v-col cols="12">
                    <v-alert
                        type="success"
                        dense
                        dismissible
                        elevation="2"
                        class="ma-0"
                    >
                        E-mail sent! Go back to
                        <router-link :to="{ name: 'signIn' }"
                            >Sign In</router-link
                        >
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="pa-6">
            <v-btn
                :loading="loading"
                block
                @click="resetPassword"
                color="primary"
                depressed
                :disabled="!valid"
            >
                Reset password
            </v-btn>
        </v-card-actions>
        <!--Error Alert-->
        <Errors />
    </v-card>
</template>

<script>
import { auth } from '../services/firebase'
import { mapMutations } from 'vuex'
export default {
    name: 'PasswordRecovery',
    components: {
        Errors: () => import('@/components/Layout/Errors.vue'),
    },
    data() {
        return {
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                email: v => /.+@.+\..+/.test(v) || 'Not a valid email',
            },
            email: null,
            loading: false,
            error: false,
            errorMsg: null,
            success: false,
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        resetPassword: async function() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                await auth().sendPasswordResetEmail(this.email)
                this.success = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
